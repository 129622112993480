.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    fill:rgb(0, 16, 247) !important;
    margin-left: -32px;
    margin-top: -32px;
    z-index: 99999;
  }
  .loading::before{
    content: "";
    position: fixed;
    left: 0;
    top:0;
    width: 100%;
    height: 100vh;
    background: rgba(245, 242, 242, 0.25);
    z-index: 1500;
    overflow: hidden;
  }
  .apexcharts-canvas .apexcharts-toolbar .apexcharts-menu .apexcharts-menu-item {
    color: #636e72;
    background-color: #0010f700 !important;
}
  .loading svg{
    z-index: 1501;
    position: relative;
  }

  h3.ant-typography:after {
    content: "";
    background: #c1c1c1;
    height: 4px;
    border-radius: 50px;
    width: 66px;
    display: block;
    margin-top: 12px;
    margin-bottom: 20px;
}
